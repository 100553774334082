'use strict';

angular.module('windmanagerApp')
  .directive('resources', function() {
    return {
      templateUrl: 'components/directives/resources/resources.html',
      restrict: 'EA',
      scope: {
        resourceType: '=',
        resourceId: '=',
      },
      controller: 'ResourcesCtrl',
      controllerAs: 'rc',
      link: function(scope, element, attrs) {
      }
    };
  });
